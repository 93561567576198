import { makeAutoObservable } from 'mobx'
import humps from 'humps'

import { IBoardFontSettings } from '@app/interfaces/boards'
import FontStyles, { FontStyleGroup } from './FontStyles'
import Board from '../Board'

export default class FontSettings implements IBoardFontSettings {
  board: Board
  primary: FontStyles
  secondary: FontStyles
  menuTitle: FontStyles
  sectionTitle: FontStyles
  tapNumber: FontStyles // deprecated
  itemTapNumber: FontStyles
  itemProducer: FontStyles
  itemName: FontStyles
  itemDetails: FontStyles
  itemStyle: FontStyles
  itemAbv: FontStyles
  itemIbu: FontStyles
  itemCalories: FontStyles
  itemCharacteristics: FontStyles
  itemVintage: FontStyles
  itemLocation: FontStyles
  itemRating: FontStyles
  itemDescription: FontStyles
  container: FontStyles
  containerPrice: FontStyles
  announcement: FontStyles
  eventPrimary: FontStyles
  eventSecondary: FontStyles
  checkinName: FontStyles
  checkinDetails: FontStyles
  footer: FontStyles
  onDeckTag: FontStyles
  newTag: FontStyles

  constructor(board: Board, fontSettings: IBoardFontSettings) {
    this.board = board

    Object.keys(fontSettings).forEach((setting) => {
      const group: FontStyleGroup = [
        'primary',
        'secondary',
        'itemDetails',
      ].includes(setting)
        ? (setting as FontStyleGroup)
        : null

      const settingKey = setting as keyof IBoardFontSettings
      this[settingKey] = new FontStyles(this, fontSettings[settingKey], group)
    })

    makeAutoObservable(this, { board: false })
  }

  convertItemDetailOrderToFontSettingKeys(index: number) {
    if (this.board.itemDetailOrder.length < index + 1) return []
    return this.board
      .getItemDetailLine(index)
      .map((detail) => `item${humps.pascalize(detail)}`)
  }

  get basePrimaryFields() {
    return this.board.primaryFields
      .map((field) => humps.camelize(field))
      .filter((field) => field !== 'primary')
  }

  get baseSecondaryFields() {
    return this.board.secondaryFields
      .map((field) => humps.camelize(field))
      .filter((field) => field !== 'secondary')
  }

  get primaryItemFields() {
    return this.convertItemDetailOrderToFontSettingKeys(0)
  }

  get secondaryItemFields() {
    return this.convertItemDetailOrderToFontSettingKeys(1)
  }

  get tertiaryItemFields() {
    return this.convertItemDetailOrderToFontSettingKeys(2)
  }

  get primaryFields() {
    const primaryFields = [
      ...this.basePrimaryFields,
      ...this.primaryItemFields,
      ...(this.board.isTableLayout ? this.tertiaryItemFields : []),
    ]

    return [...new Set(primaryFields)]
  }

  get secondaryFields() {
    const secondaryFields = [
      ...this.secondaryItemFields,
      ...this.baseSecondaryFields,
      ...(this.board.isTableLayout ? [] : this.tertiaryItemFields),
    ]

    return [...new Set(secondaryFields)]
  }

  get itemDetailFields() {
    const fields = this.convertItemDetailOrderToFontSettingKeys(1)
    if (this.board.isTableLayout) return fields

    return fields.concat(this.tertiaryItemFields)
  }

  get attributes() {
    return {
      primary: this.primary.attributes,
      secondary: this.secondary.attributes,
      menuTitle: this.menuTitle.attributes,
      sectionTitle: this.sectionTitle.attributes,
      tapNumber: this.tapNumber.attributes,
      itemTapNumber: this.itemTapNumber.attributes,
      itemProducer: this.itemProducer.attributes,
      itemName: this.itemName.attributes,
      itemStyle: this.itemStyle.attributes,
      itemAbv: this.itemAbv.attributes,
      itemIbu: this.itemIbu.attributes,
      itemCalories: this.itemCalories.attributes,
      itemCharacteristics: this.itemCharacteristics.attributes,
      itemVintage: this.itemVintage.attributes,
      itemLocation: this.itemLocation.attributes,
      itemRating: this.itemRating.attributes,
      itemDetails: this.itemDetails.attributes,
      itemDescription: this.itemDescription.attributes,
      container: this.container.attributes,
      containerPrice: this.containerPrice.attributes,
      announcement: this.announcement.attributes,
      eventPrimary: this.eventPrimary.attributes,
      eventSecondary: this.eventSecondary.attributes,
      checkinName: this.checkinName.attributes,
      checkinDetails: this.checkinDetails.attributes,
      footer: this.footer.attributes,
      onDeckTag: this.onDeckTag.attributes,
      newTag: this.newTag.attributes,
    }
  }
}
