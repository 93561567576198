import { VenueHealth } from '@app/interfaces/shared'

import Base from './Base'

class Locations extends Base {
  static async update(id: number, params: object) {
    return await this.patch(`/locations/${id}`, params)
  }

  static async health(id: number): Promise<VenueHealth> {
    const { data } = await this.get(`/locations/${id}/health`)
    return data
  }

  static async topBeers(id: number) {
    return await this.get(`/locations/${id}/top_beers`)
  }
}

export default Locations
