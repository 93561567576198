import { createConsumer } from '@rails/actioncable'

import Board from './Board'

const consumer = createConsumer()

export default class Channels {
  board: Board

  constructor(board: Board) {
    this.board = board
  }

  subscribe = () => {
    this.subscribeToBoardUpdates()
    this.subscribeToLocationUpdates()
    this.subscribeToUntappdCheckins()
  }

  subscribeToBoardUpdates = () => {
    this.subscribeToChannel('DigitalBoardsChannel', this.board.id)
  }

  subscribeToLocationUpdates = () => {
    this.subscribeToChannel('LocationsChannel', this.board.locationId)
  }

  subscribeToUntappdCheckins = () => {
    this.subscribeToChannel('UntappdCheckinChannel', this.board.locationId)
  }

  subscribeToChannel = (channel: string, id: number) => {
    const cableConfig = {
      channel,
      id,
    }

    const refresh = this.board.refresh
    consumer.subscriptions.create(cableConfig, {
      received(data) {
        if (data.reload) window.location.reload()
        else refresh()
      },
    })
  }
}
