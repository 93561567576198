import { FontSizing } from '@app/interfaces/boards'
import Board from './Board'

interface ResizeOptions {
  max: number
  min: number
}

export default class FontResizer {
  board: Board

  constructor(board: Board) {
    this.board = board
  }

  run = () => {
    this.resizeToParent()
    this.evenLabelImageHeight()

    const elements = document.querySelectorAll(
      `.text-fit`,
    ) as NodeListOf<HTMLElement>

    this.uncollapseFlexColumns()

    if (this.board.fontAutosizing) this.resize(elements)

    this.collapseFlexColumns()
  }

  resizeToParent = () => {
    const elements = document.querySelectorAll(
      '.resize-to-parent',
    ) as NodeListOf<HTMLElement>
    elements.forEach((el) => {
      el.style.height = el.parentElement?.offsetHeight + 'px'
    })
  }

  evenLabelImageHeight = () => {
    const labelImages = document.querySelectorAll(
      '.label-image',
    ) as NodeListOf<HTMLElement>
    let minHeight = 99999
    labelImages.forEach((el) => {
      el.style.height = '100%'
      if (el.offsetHeight < minHeight) minHeight = el.offsetHeight
    })
    labelImages.forEach((el) => {
      el.style.height = `${minHeight}px`
    })
  }

  resize = (elements: NodeListOf<HTMLElement>) => {
    const { text } = this.board.settings

    if (this.board.fontSizing === FontSizing.SHRINK_TO_FIT) {
      this.resizeToFit(elements, {
        max: text.basic.maxFont,
        min: text.basic.minFont,
      })
    } else {
      this.resizeConsistently('standalone', {
        max: text.basic.maxFont,
        min: text.basic.minFont,
      })
      const basicFontSize = this.resizeConsistently('basic', {
        max: text.basic.maxFont,
        min: text.basic.minFont,
      })
      const detailFontSize = this.resizeConsistently('detail', {
        max: basicFontSize,
        min: text.detail.minFont,
      })
      this.resizeConsistently('generic-item-description', {
        max: detailFontSize,
        min: text.detail.minFont,
      })
      this.resizeConsistently('containers', {
        max:
          this.board.containerLayout === 'below'
            ? detailFontSize
            : basicFontSize,
        min: text.containers.minFont,
      })
    }
  }

  resizeToFit = (elements: NodeListOf<HTMLElement>, options: ResizeOptions) => {
    elements.forEach((el) => {
      el.style.fontSize = `${options.max}px`
    })

    elements.forEach((el) => {
      let fontSize = this.fitHeight(el, options.max, options)
      el.style.fontSize = `${fontSize}px`
      fontSize = this.fitWidth(el, fontSize, options)
      el.style.fontSize = `${fontSize}px`
    })
  }

  resizeConsistently = (field: string, options: ResizeOptions) => {
    const elements = document.querySelectorAll(
      `.${field}`,
    ) as NodeListOf<HTMLElement>

    const fontSize = this.fitElementHeights(elements, options)
    return this.fitElementWidths(elements, {
      max: fontSize,
      min: options.min,
    })
  }

  fitElementHeights = (
    elements: NodeListOf<HTMLElement>,
    options: ResizeOptions,
  ) => {
    let fontSize = options.max
    elements.forEach((el) => {
      el.style.fontSize = `${fontSize}px`
    })

    elements.forEach((el) => {
      fontSize = this.fitHeight(el, fontSize, options)
    })

    elements.forEach((el) => {
      el.style.fontSize = `${fontSize}px`
    })

    return fontSize
  }

  fitElementWidths = (
    elements: NodeListOf<HTMLElement>,
    options: ResizeOptions,
  ) => {
    let fontSize = options.max
    elements.forEach((el) => {
      fontSize = this.fitWidth(el, fontSize, options)
    })

    elements.forEach((el) => {
      el.style.fontSize = `${fontSize}px`
    })

    return fontSize
  }

  fitHeight = (
    el: HTMLElement,
    fontSize: number,
    { max, min }: ResizeOptions,
  ) => {
    const { parentElement } = el
    if (!parentElement) return fontSize

    const outerHeight = parentElement.offsetHeight
    const computedStyle = getComputedStyle(el)
    const innerHeight = el.offsetHeight
    const multiplier = outerHeight / innerHeight
    const oldFontPixels = parseInt(computedStyle.fontSize, 10)
    const newFont = Math.floor(oldFontPixels * multiplier)

    if (el.getAttribute('data-debug')) {
      console.log({
        outerHeight,
        innerHeight,
        multiplier,
        oldFontPixels,
        newFont,
        fontSize,
      })
    }

    if (newFont < fontSize && newFont >= min && newFont <= max) return newFont
    if (newFont < min) return min
    return fontSize
  }

  fitWidth = (
    el: HTMLElement,
    fontSize: number,
    { max, min }: ResizeOptions,
  ) => {
    const { parentElement } = el
    if (!parentElement) return fontSize
    const outerWidth = parentElement.offsetWidth
    const computedStyle = getComputedStyle(el)
    const innerWidth = el.offsetWidth
    const multiplier = outerWidth / innerWidth
    const oldFontPixels = parseInt(computedStyle.fontSize, 10)
    const newFont = Math.floor(oldFontPixels * multiplier)

    if (el.getAttribute('data-debug')) {
      console.log({
        outerWidth,
        innerWidth,
        multiplier,
        oldFontPixels,
        newFont,
        fontSize,
      })
    }

    if (newFont < fontSize && newFont >= min && newFont <= max) return newFont
    if (newFont < min) return min
    return fontSize
  }

  collapseFlexColumns = () => {
    const elements = this.queryCollapsibleFlexColumns()
    elements.forEach((el) => {
      el.style.flex = 'unset'
    })
  }

  uncollapseFlexColumns = () => {
    const elements = this.queryCollapsibleFlexColumns()
    elements.forEach((el) => {
      el.style.flex = '1'
    })
  }

  queryCollapsibleFlexColumns = () => {
    return document.querySelectorAll(
      '.collapse > div',
    ) as NodeListOf<HTMLElement>
  }

  get text() {
    return this.board.settings.text
  }
}
