import { makeAutoObservable } from 'mobx'

import { IMenuFull } from '@app/interfaces/shared'
import Board from './Board'
import Section from './Section'

export default class Menu implements IMenuFull {
  board: Board
  id: number
  locationId: number
  name: string
  unpublished: boolean
  updatedAt: string
  showPriceOnUntappd: boolean
  itemsCount: number
  sections: Section[]
  onDeckSection: Section | null = null

  constructor(board: Board, menu: IMenuFull) {
    this.board = board
    this.id = menu.id
    this.name = menu.name
    this.sections = menu.sections.map((section) => new Section(this, section))

    if (menu.onDeckSection) {
      this.onDeckSection = new Section(this, menu.onDeckSection)
    }

    makeAutoObservable(this, { board: false })
  }
}
