import { makeAutoObservable } from 'mobx'

import { IBoardOptions } from '@app/interfaces/boards'
import RootStore from '@app/stores/RootStore'
import DigitalBoards from '@app/lib/api/DigitalBoards'

import Board from './Board'
import { AxiosError } from 'axios'

export default class DigitalBoardStore {
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this, { rootStore: false })
  }

  rootStore: RootStore

  board: Board | null = null

  isLoading = false

  loadBoard = async (
    id: number,
    options: IBoardOptions = {
      public: false,
      page: 0,
    },
  ) => {
    this.setIsLoading(true)
    try {
      const { data } = await DigitalBoards.retrieve(id, {
        public: options.public,
      })
      const board = new Board(this, data.digitalBoard, options)
      if (
        !board.hasNewExperience &&
        !window.location.search.includes('new_experience=true')
      ) {
        window.location.href = `/boards/${board.id}`
        return
      }

      this.setBoard(board)
    } catch (e) {
      this.rootStore.handleAxiosError(e as AxiosError)
      console.error(e)
    } finally {
      this.setIsLoading(false)
    }
  }

  setBoard = (board: Board | null) => {
    this.board = board
  }

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading
  }
}
