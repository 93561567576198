import qs from 'qs'

import Base from './Base'

class DigitalBoards extends Base {
  static retrieve(id: number, params = {}) {
    return this.get(`/digital_boards/${id}?${qs.stringify(params)}`)
  }

  static async list(locationId: number, params = {}) {
    return this.get(
      `locations/${locationId}/digital_boards?${qs.stringify(params)}`,
    )
  }

  static async create(locationId: number, params: object) {
    return this.post(`locations/${locationId}/digital_boards`, params)
  }

  static update(id: number, params: object) {
    return this.put(`/digital_boards/${id}`, params)
  }

  static async destroy(id: number) {
    return this.delete(`/digital_boards/${id}`)
  }

  static async duplicate(id: number) {
    return this.post(`/digital_boards/${id}/duplicate`)
  }

  static uploadBackgroundImage(id: number, image: File | null) {
    if (!image)
      return this.patch(`/digital_boards/${id}`, { purgeBackgroundImage: true })

    const data = new FormData()
    data.append('background_image', image)
    data.append('background_image_file_name', image.name)
    return this.patch(`/digital_boards/${id}`, data, {
      transformRequest: (data: object) => data,
    })
  }

  static uploadHeaderImage(id: number, image: File | null) {
    if (!image)
      return this.patch(`/digital_boards/${id}`, { purgeHeaderImage: true })

    const data = new FormData()
    data.append('header_image', image)
    data.append('header_image_file_name', image.name)
    return this.patch(`/digital_boards/${id}`, data, {
      transformRequest: (data: object) => data,
    })
  }

  static uploadFont(id: number, font: File) {
    const data = new FormData()
    data.append('font', font)
    return this.patch(`/digital_boards/${id}`, data, {
      transformRequest: (data: object) => data,
    })
  }

  static menus(id: number, params = {}) {
    return this.get(`/digital_boards/${id}/menus?${qs.stringify(params)}`)
  }

  static adSlides(id: number, params = {}) {
    return this.get(`/digital_boards/${id}/ad_slides?${qs.stringify(params)}`)
  }

  static checkins(id: number, params = {}) {
    return this.get(`/digital_boards/${id}/checkins?${qs.stringify(params)}`)
  }

  static events(id: number, params = {}) {
    return this.get(`/digital_boards/${id}/events?${qs.stringify(params)}`)
  }

  static trackDevice = (id: number) => {
    const fireTvAppVersion = localStorage.getItem('fireTvAppVersion')
    const httpConfig = fireTvAppVersion
      ? {
          headers: {
            'x-fire-tv-app-version': fireTvAppVersion,
          },
        }
      : {}

    return this.get(`/digital_boards/${id}/track_device`, httpConfig)
  }
}

export default DigitalBoards
