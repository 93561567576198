import { makeAutoObservable } from 'mobx'

import Menu from './Menu'
import Item from './Item'
import { ISection } from '@app/interfaces/shared'

export default class Section implements ISection {
  menu: Menu
  menuId: number
  id: number
  name: string
  description: string
  updatedAt: string
  createdAt: string
  public: boolean
  itemsCount: number
  type: 'Section' | 'OnDeckSection'
  items: Item[]

  constructor(menu: Menu, section: ISection) {
    this.menu = menu
    this.id = section.id
    this.name = section.name
    this.itemsCount = section.itemsCount
    this.type = section.type
    this.public = section.public
    this.items = section.items.map((item) => new Item(this, item))

    makeAutoObservable(this, { menu: false })
  }
}
