import { makeAutoObservable } from 'mobx'

import { IItem, IContainer } from '@app/interfaces/shared'
import Section from './Section'

export default class Item implements IItem {
  section: Section
  id: number
  name: string
  brewery?: string | null
  producer?: string | null
  style: string | null
  category?: string | null
  vintage?: string | null
  characteristics?: string | null
  location?: string | null
  breweryLocation?: string | null
  abv?: number | null
  ibu?: number | null
  calories?: string | null
  labelImage: string
  labelImageThumb: string
  type: string
  description?: string
  rating?: number
  tapNumber?: string
  inProduction: boolean
  untappdId?: number
  sourceIdentifier?: string
  itemSourceId?: number
  nitro: boolean
  cask: boolean
  local: boolean
  customLabelImage?: string | null
  customLabelImageThumb?: string | null
  customLabelImageFilename?: string | null
  updatedAt: string
  sectionId: number
  containers: IContainer[]
  createdAt: string
  position: number

  image = new Image()
  imageSrc = '/images/100x100.png'
  showSectionName = false

  constructor(section: Section, item: IItem) {
    this.section = section
    this.set(item)

    // preload the image
    this.image.src = this.labelImageSrc
    this.image.onload = () => this.preloadImage()

    makeAutoObservable(this, { section: false })
  }

  set = (item: Partial<IItem>) => {
    Object.assign(this, {
      ...item,
      location: item.breweryLocation || item.location,
      style: item.style || item.category,
      producer: item.brewery || item.producer,
      ibu: Number(item.ibu),
      abv: Number(item.abv),
    })
  }

  setShowSectionName = (showSectionName: boolean) => {
    this.showSectionName = showSectionName
  }

  preloadImage = () => {
    if (this.image.src === this.labelImageSrc) return

    this.image.src = this.labelImageSrc
    this.labelImageSrc
  }

  get isOnDeck() {
    return this.section.type === 'OnDeckSection'
  }

  get containersBySize() {
    return this.containers.reduce((acc, container) => {
      acc.set(container.name || '', container)
      return acc
    }, new Map<string, IContainer>())
  }

  get tags() {
    const tags = []

    if (this.cask) tags.push('Cask')
    if (this.nitro) tags.push('Nitro')
    if (this.local) tags.push('Local')

    return tags
  }

  get labelImageSrc() {
    const src = this.labelImageThumb
      // Reset winesearcher images to size 100
      .replace('width=50', 'width=100')
      .replace('height=50', 'height=100')
      .replace('canvas=50,50', 'canvas=100,100')

    if (src.includes('https://s3.amazonaws.com/woof.nextglass.co/default/')) {
      return src.replace('.svg', '_square.png')
    }

    return src
  }

  get containersWidthPercentage() {
    const { containerLayout, showContainers } = this.section.menu.board
    const containerCounts = this.containers.length

    if (containerLayout === 'below' || containerCounts < 1 || !showContainers) {
      return 0
    }

    if (containerLayout === 'stacked') return 25

    if (containerCounts < 2) return 15
    if (containerCounts < 3) return 25
    if (containerCounts < 5) return 33
    return 50
  }
}
