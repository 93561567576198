import { makeAutoObservable, reaction } from 'mobx'
import { arrayMove } from '@dnd-kit/sortable'

import { IAdSlide } from '@app/interfaces/boards'
import Slides from '@app/lib/api/AdSlides'
import Board from '../Board'
import AdSlide from './AdSlide'

export default class Ads {
  digitalBoard: Board
  adSlides: AdSlide[]
  isLoading = false
  visible = false
  currentSlideIndex = 0
  rotationIntervalId: NodeJS.Timeout | null = null
  decisecondsElapsed = 0

  constructor(digitalBoard: Board, adSlides: IAdSlide[] = []) {
    this.digitalBoard = digitalBoard
    this.adSlides = adSlides.map((adSlide) => new AdSlide(adSlide))

    makeAutoObservable(this, { digitalBoard: false })

    reaction(
      () => this.adSlidesWithContent.length,
      () => {
        if (this.adSlidesWithContent.length < 1) {
          this.visible = false
        } else if (!this.visible && !this.digitalBoard.hasItems) {
          this.startSlideRotation()
        }
      },
    )
  }

  setAdSlides = (adSlides: AdSlide[]) => {
    this.adSlides = adSlides
  }

  create = async () => {
    this.setIsLoading(true)
    const response = await Slides.create(this.digitalBoard.id)
    const slideData = response.data
    const newSlide = new AdSlide(slideData)
    this.adSlides.forEach((slide) => slide.setExpanded(false))
    this.adSlides = this.adSlides.concat(newSlide)
    this.setIsLoading(false)
  }

  sort = async (oldIndex: number, newIndex: number) => {
    this.setAdSlides(arrayMove(this.adSlides, oldIndex, newIndex))
    Slides.sort(
      this.digitalBoard.id,
      this.adSlides.map((slide) => slide.id),
    )
  }

  destroy = async (slideId: number) => {
    this.adSlides = this.adSlides.filter((slide) => slide.id !== slideId)
    this.currentSlideIndex = Math.min(
      this.currentSlideIndex,
      this.adSlidesWithContent.length - 1,
    )
    Slides.destroy(slideId)
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }

  startSlideRotation = () => {
    if (this.rotationIntervalId) clearInterval(this.rotationIntervalId)
    if (this.adSlidesWithContent.length < 1) {
      this.rotateItems(this.digitalBoard.page)
      return
    }
    this.rotationIntervalId = setInterval(this.updateProgress, 100)
    this.visible = true
  }

  updateProgress = () => {
    this.decisecondsElapsed++

    if (!this.currentAd) {
      clearInterval(this.rotationIntervalId!)
      return
    }
    if (this.decisecondsElapsed >= this.displayDeciseconds) {
      this.nextAdSlide()
    }
  }

  previousAdSlide = ({ manual } = { manual: false }) => {
    this.decisecondsElapsed = 0

    if (
      this.onFirstSlide ||
      this.digitalBoard.adSlideDisplayStyle === 'one_each_loop'
    ) {
      const nextDigitalBoardPage =
        this.digitalBoard.multipleDisplays && !manual
          ? this.digitalBoard.page
          : this.digitalBoard.pageCount - 1
      this.rotateItems(nextDigitalBoardPage)
    }

    this.currentSlideIndex = this.onFirstSlide
      ? this.adSlidesWithContent.length - 1
      : this.currentSlideIndex - 1
  }

  nextAdSlide = ({ manual } = { manual: false }) => {
    this.decisecondsElapsed = 0

    if (
      this.digitalBoard.adSlideDisplayStyle === 'one_each_loop' ||
      this.onLastSlide
    ) {
      const nextDigitalBoardPage =
        this.digitalBoard.multipleDisplays && !manual
          ? this.digitalBoard.page
          : 0
      this.rotateItems(nextDigitalBoardPage)
    }

    this.currentSlideIndex =
      (this.currentSlideIndex + 1) % this.adSlidesWithContent.length
  }

  rotateItems = (page: number) => {
    if (!this.digitalBoard.hasItems) return
    if (this.rotationIntervalId) clearInterval(this.rotationIntervalId)
    this.visible = false
    this.digitalBoard.setField('page', page)
    this.digitalBoard.startPagination()
  }

  setField = <K extends keyof typeof this>(
    field: string,
    value: (typeof this)[K],
  ) => {
    this[field as keyof typeof this] = value
  }

  get onFirstSlide() {
    return this.currentSlideIndex <= 0
  }

  get onLastSlide() {
    return this.currentSlideIndex >= this.adSlidesWithContent.length - 1
  }

  get hasAdSlides() {
    return this.adSlidesWithContent.length > 0
  }

  get currentAd() {
    return this.adSlidesWithContent[this.currentSlideIndex]
  }

  get adSlidesWithContent() {
    return this.adSlides.filter((slide) => !!slide.imageUrl)
  }

  get rotationProgressPercentage() {
    return (this.decisecondsElapsed / this.displayDeciseconds) * 100
  }

  get slideCount() {
    return this.adSlidesWithContent.length
  }

  get displayDeciseconds() {
    return this.currentAd.displaySeconds * 10
  }
}
