import Base from './Base'

class AdSlides extends Base {
  static create(boardId: number) {
    return this.post(`/digital_boards/${boardId}/ad_slides`)
  }

  static update(adSlideId: number, params: object) {
    return this.patch(`/ad_slides/${adSlideId}`, params)
  }

  static destroy(adSlideId: number) {
    return this.delete(`/ad_slides/${adSlideId}`)
  }

  static sort(boardId: number, adSlideIds: number[]) {
    return this.patch(`/digital_boards/${boardId}/ad_slides/sort`, {
      adSlideIds,
    })
  }

  static uploadImage(slideId: number, image: File | null) {
    if (!image)
      return this.patch(`/ad_slides/${slideId}`, {
        purgeImage: true,
      })

    const data = new FormData()
    data.append('image', image)
    data.append('image_file_name', image.name)
    return this.patch(`/ad_slides/${slideId}`, data, {
      transformRequest: (data: object) => data,
    })
  }
}

export default AdSlides
