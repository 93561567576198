import { ModulizedItemType } from '@app/interfaces/shared'

export const FOOTER_LINKS = [
  {
    title: 'API',
    href: 'https://docs.business.untappd.com/',
  },
  {
    title: 'Contact Us',
    href: '/submit-issue',
  },
  {
    title: 'Terms of Service',
    href: '/pages/terms',
  },
  {
    title: 'Privacy Policy',
    href: '/pages/privacy_policy',
  },
  {
    title: 'Free Trial Agreement',
    href: '/pages/free_trial_agreement',
  },
  {
    title: 'SaaS Agreement',
    href: '/pages/saas_agreement',
  },
  {
    title: 'Support',
    href: 'https://untappd.zendesk.com',
  },
]

export const SUBSCRIPTION_STATUS = {
  TRIALING: 'trialing',
  SUSPENDED: 'suspended',
  ACTIVE: 'active',
}

export const FUSEBILL_USA_COUNTRY_ID = 840

export const DEFAULT_FOURSQUARE_IMG =
  'https://foursquare.com/img/categories_v2/none_bg_512.png'

export const PHONE_NUMBER_FORMAT = /^[+()\-\d\s]+$/

export const SALES_EMAIL = 'sales@untappd.com'

export const ITEM_PAGE_SIZE = 50

export const BEER_ITEM_TYPE = 'beer'
export const WINE_ITEM_TYPE = 'wine'
export const SPIRIT_ITEM_TYPE = 'spirit'
export const READY_TO_DRINK_ITEM_TYPE = 'ready_to_drink'
export const FOOD_ITEM_TYPE = 'food'
export const COCKTAIL_ITEM_TYPE = 'cocktail'
export const FLIGHT_ITEM_TYPE = 'flight'
export const NON_ALCOHOLIC_ITEM_TYPE = 'non_alcoholic'
export const MERCHANDISE_ITEM_TYPE = 'merchandise'
export const MISCELLANEOUS_ITEM_TYPE = 'miscellaneous'

export const ITEM_TYPES = [
  BEER_ITEM_TYPE,
  WINE_ITEM_TYPE,
  SPIRIT_ITEM_TYPE,
  READY_TO_DRINK_ITEM_TYPE,
  FOOD_ITEM_TYPE,
  COCKTAIL_ITEM_TYPE,
  FLIGHT_ITEM_TYPE,
  NON_ALCOHOLIC_ITEM_TYPE,
  MERCHANDISE_ITEM_TYPE,
  MISCELLANEOUS_ITEM_TYPE,
]

export const MODULIZED_ITEM_TYPES: { [key: string]: ModulizedItemType } = {
  [BEER_ITEM_TYPE]: 'Items::Beer',
  [WINE_ITEM_TYPE]: 'Items::Wine',
  [SPIRIT_ITEM_TYPE]: 'Items::Spirit',
  [READY_TO_DRINK_ITEM_TYPE]: 'Items::ReadyToDrink',
  [FOOD_ITEM_TYPE]: 'Items::Food',
  [COCKTAIL_ITEM_TYPE]: 'Items::Cocktail',
  [FLIGHT_ITEM_TYPE]: 'Items::Flight',
  [NON_ALCOHOLIC_ITEM_TYPE]: 'Items::NonAlcoholic',
  [MERCHANDISE_ITEM_TYPE]: 'Items::Merchandise',
  [MISCELLANEOUS_ITEM_TYPE]: 'Items::Miscellaneous',
}

export const ITEM_OPTIONS = [
  { label: 'Beer', value: BEER_ITEM_TYPE },
  { label: 'Wine', value: WINE_ITEM_TYPE },
  { label: 'Spirit', value: SPIRIT_ITEM_TYPE },
  { label: 'Ready to Drink', value: READY_TO_DRINK_ITEM_TYPE },
  { label: 'Food', value: FOOD_ITEM_TYPE },
  { label: 'Cocktail', value: COCKTAIL_ITEM_TYPE },
  { label: 'Flight', value: FLIGHT_ITEM_TYPE },
  { label: 'N/A Bev', value: NON_ALCOHOLIC_ITEM_TYPE },
  { label: 'Merch', value: MERCHANDISE_ITEM_TYPE },
  { label: 'Misc', value: MISCELLANEOUS_ITEM_TYPE },
]

export const CUSTOM_CONTAINER_NAME_LABELS: Record<string | number, string> = {
  [MERCHANDISE_ITEM_TYPE]: 'Component',
  [MISCELLANEOUS_ITEM_TYPE]: 'Component',
}
