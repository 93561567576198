import { makeAutoObservable } from 'mobx'
import WebFont from 'webfontloader'

import DigitalBoards from '@app/lib/api/DigitalBoards'
import Board from './Board'

export default class Fonts {
  board: Board
  loaded = false
  uploading = false

  constructor(board: Board) {
    this.board = board
    this.load()

    makeAutoObservable(this, { board: false })
  }

  load = () => {
    WebFont.load({
      google: {
        families: Object.values(this.board.fontFamilies).flatMap(
          (families) => families,
        ),
      },
      active: async () => {
        this.board.customFontFamilies.forEach(async (font) => {
          const fontFace = new FontFace(font.name, `url(${font.url})`)
          document.fonts.add(fontFace)
          await fontFace.load()
        })

        await document.fonts.ready
        this.setLoaded(true)
      },
    })
  }

  upload = async (file: File | null) => {
    if (!file) return
    this.setUploading(true)
    const {
      data: {
        digitalBoard: { customFontFamilies },
      },
    } = await DigitalBoards.uploadFont(this.board.id, file)
    this.board.set({ customFontFamilies })

    if (customFontFamilies.length) {
      const font = customFontFamilies[customFontFamilies.length - 1]
      const fontFace = new FontFace(font.name, `url(${font.url})`)
      document.fonts.add(fontFace)
      await fontFace.load()
      await document.fonts.ready
    }
    this.setUploading(false)
  }

  setLoaded = (loaded: boolean) => {
    this.loaded = loaded
  }

  setUploading = (uploading: boolean) => {
    this.uploading = uploading
  }

  get fontFamilyOptions() {
    const fontFamilyOptions = Object.entries(this.board.fontFamilies).flatMap(
      ([group, families]) => {
        return families.map((font) => ({
          label: font,
          value: font,
          group,
        }))
      },
      [],
    )

    if (this.board.customFontFamilies.length) {
      return [
        ...fontFamilyOptions,
        ...this.board.customFontFamilies.map((font) => ({
          label: font.name,
          value: font.name,
          group: 'Custom Fonts',
        })),
      ]
    }

    return fontFamilyOptions
  }
}
