import { makeAutoObservable } from 'mobx'

import { IAdSlide } from '@app/interfaces/boards'
import Slides from '@app/lib/api/AdSlides'

export default class AdSlide implements IAdSlide {
  id: number
  imageUrl: string | null = null
  imageFileName: string | null = null
  displaySeconds: number
  expanded: boolean

  constructor(adSlide: IAdSlide) {
    this.set(adSlide)
    this.expanded = this.isNew

    makeAutoObservable(this)
  }

  uploadImage = async (file: File | null) => {
    this.imageUrl = file ? URL.createObjectURL(file) : null
    this.imageFileName = file ? file.name : null
    Slides.uploadImage(this.id, file)
  }

  updateDuration = async (duration: number) => {
    this.displaySeconds = duration

    Slides.update(this.id, { displaySeconds: duration })
  }

  set = (adSlide: IAdSlide) => {
    Object.assign(this, adSlide)
  }

  setExpanded(expanded: boolean) {
    this.expanded = expanded
  }

  get isNew() {
    return !this.imageUrl
  }
}
